<template>
  <v-card flat class="pa-4">
    <v-row v-if="checkRoles()">
      <v-col cols="12">
        <v-tabs v-model="tab" center-active @change="tabValue">
          <v-tab>
            <v-icon class="mr-2">mdi-account-check</v-icon>
            {{ $t("master_data.student.tab1_title") }}
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">mdi-account-remove</v-icon>
            {{ $t("master_data.student.tab2_title") }}
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">mdi-account-plus</v-icon>
            {{ $t("master_data.student.tab3_title") }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item class="my-4">
            <Student />
          </v-tab-item>
          <v-tab-item>
            <StudentMutation />
          </v-tab-item>
          <v-tab-item class="py-4">
            <StudentCandidate />
            <AddData
              :title="$t('master_data.student.dialog_1.title')"
              :content1="$t('master_data.student.dialog_1.content_1')"
              :content2="$t('master_data.student.dialog_1.content_2')"
              path="student-manual"
              type="student"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-tabs v-model="tabHomeroom" center-active class="mb-2">
          <v-tab>
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ $t("master_data.student.homeroom_teacher") }}
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">mdi-account-group</v-icon>
            {{ $t("master_data.student.teaching_class") }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabHomeroom" touchless>
          <v-tab-item> </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12">
        <Student
          :init="$route.query.tab == 0"
          :tabHomeroom="tabHomeroom"
          isHomeroom
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: `Master ${i18n.t("routes.student")}`,
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    AddData: () => import("../components/AddData"),
    Student: () => import("./Student"),
    StudentMutation: () => import("./StudentMutation"),
    StudentCandidate: () => import("./StudentCandidate")
  },
  data() {
    return {
      tab: this.$route.query.tab | 0,
      tabHomeroom: this.$route.query.tab | 0
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  computed: mapGetters(["g_roles"]),
  methods: {
    checkRoles() {
      const checkRoles = this.g_roles.filter(r => r == 4).length > 0;
      return checkRoles;
    },
    tabValue(tab) {
      this.$router.push({ name: "Student", query: { tab: tab } });
    }
  }
};
</script>
